module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Chris Tse Portfolio","short_name":"Chris Tse","start_url":"/","background_color":"#fff","theme_color":"#553c9a","display":"minimal-ui","icon":"src/images/favicon-transparent.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
